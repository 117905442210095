import React, { useState } from "react"

import { graphql } from "gatsby"

import { useTranslation, FormatDate } from "../i18n"

import { Seo } from "../seo/"

import GridLayout from "../Layout/GridLayout"
import { Image, Icon, Button } from "../Layout/Components"
import { Popover } from "antd"
import { TestimonialData } from "../rows/Testimonials"

type Props = {
  data: {
    wordPress: {
      testimonials: {
        nodes: TestimonialData[]
      }
    }
  }
}

const Testimonianze: React.FC<Props> = ({
  data: {
    wordPress: {
      testimonials: { nodes: testimonials },
    },
  },
}) => {
  const { t } = useTranslation([])

  const Testimonal: React.FC<{ data: TestimonialData }> = ({ data: testimonial }) => {
    // console.log("Newspaper: ", article)
    // console.log("Newspaper: ", article.newspapers.nodes[0]?.slug)

    const [isOpen, setIsOpen] = useState(false)

    return (
      <div className="card__testimonial-horiz">
        <div className="card__testimonial-horiz-cover">
          <a id={testimonial.id.replace("=", "")}></a>
          <Image file={testimonial.details.photo} type="cover" className="card__testimonial-horiz-image" />
          <div className="card__testimonial-horiz-extra-info">
            {testimonial.details.car.carModel ? (
              <Popover title={t(testimonial.details.car.carAction)} content={testimonial.details.car.carModel} placement="top">
                <div style={{ display: "inline", marginRight: "1rem" }}>
                  <Icon name="fas:car" />
                </div>
              </Popover>
            ) : null}
            {testimonial.details.boughCourse ? (
              <Popover title={t("Corso frequentato")} content={testimonial.details.boughCourse} placement="top">
                <div style={{ display: "inline", marginRight: "1rem" }}>
                  <Icon name="fas:graduation-cap" />
                </div>
              </Popover>
            ) : null}
            {testimonial.details.attendedRace ? (
              <Popover title={t("Ecoverso r-ace frequentata")} content={testimonial.details.attendedRace} placement="top">
                <div style={{ display: "inline", marginRight: "1rem" }}>
                  <Icon name="fas:trophy" />
                </div>
              </Popover>
            ) : null}
          </div>
        </div>
        <div className={"card__testimonial-horiz-content " + (isOpen ? "card__testimonial-horiz-content--open" : "")}>
          <h3 className="card__testimonial-horiz-title">{testimonial.title}</h3>
          <div className="card__testimonial-horiz-description" dangerouslySetInnerHTML={{ __html: testimonial.details.testimonial }}></div>
          <div className="card__testimonial-horiz-content-background"></div>
          <div className="card__testimonial-horiz-date">
            {testimonial.details.location}, <FormatDate date={testimonial.date} />
          </div>
          <div className="card__testimonial-horiz-read-all">
            {isOpen ? (
              <Button to={null} title={t("Riduci")} type="ghost" onClick={() => setIsOpen(false)} />
            ) : (
              <Button to={null} title={t("Leggi tutto")} type="ghost" onClick={() => setIsOpen(true)} />
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <GridLayout>
      <Seo title={t("Testimonianze")} />
      <div className="row row--center">
        <div className="col--12">
          <h1>{t("La testimonianze dei nostri associati")}</h1>
          <p className="mb-none">{t("Queste sono le testimonianze dei nostri associati. Non credete a noi, CREDETE A LORO!")}</p>
        </div>
      </div>
      <div className="row row--full row__background--straight pt-none pb-none">
        <div className="row row--center row__background--straight row--no-grid">
          <div className="testimonials">
            {testimonials.map((testimonial, i) => (
              <Testimonal data={testimonial} key={i} />
            ))}
          </div>
        </div>
      </div>
    </GridLayout>
  )
}

export const query = graphql`
  query TestimonialsQuery {
    wordPress {
      testimonials(where: { orderby: { field: DATE, order: DESC } }, first: 1000) {
        nodes {
          ...testimonial
        }
      }
    }
  }
`

export default Testimonianze
